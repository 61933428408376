import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		sessionId: null,  // SESSION ID
		language: null
	},
	getters: {
		sessionId: (state)=> {
			if (state.sessionId == null) {
				if (localStorage.getItem('sessionId')!=null) {
					state.sessionId = localStorage.getItem('sessionId');
				}
			}
			return state.sessionId;
		},
		language: (state)=> {
			if (state.language == null) {
				if (localStorage.getItem('language')!=null) {
					state.language = localStorage.getItem('language');
				} else {
					localStorage.setItem('language', 'cn');
					state.language = "cn";
				}
			}
			return state.language;
		}
	},
	mutations: {
		setSessionId: function(state, sessionId) {
			localStorage.setItem('sessionId', sessionId);
			state.sessionId = localStorage.getItem('sessionId');
		},
		setLanguage: function(state, language) {
			localStorage.setItem('language', language);
			state.language = localStorage.getItem('language');
		}
	},
	actions: {
	},
	modules: {
	}
})
